@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Import Inter from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* Apply global styles */
body {
  @apply font-sans; /* Use Tailwind's font-sans class (Inter is defined as the default in tailwind.config.js) */
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gray-800;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.tiptap-editor *:focus {
  outline: none !important;
}

.tiptap-editor h1 {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}

.tiptap-editor h2 {
  font-size: 1.25em;
  font-weight: bold;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}

.tiptap-editor h3 {
  font-size: 1em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
}

.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  @apply text-placeholder;
  pointer-events: none;
  height: 0;
}

.tiptap-task-item {
  display: flex;
  align-items: center;
}

.tiptap-task-item input[type="checkbox"] {
  margin-right: 8px; /* Space between checkbox and text */
}